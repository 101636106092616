import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Tooltip, Cell } from "recharts";

const CoralChart = ({ data }) => {

  const [coralPieData, setCoralPieData] = useState([]);


  const COLORS = ['#D60000', '#8C3BFF', '#018700', '#00ACC6', '#97FF00', '#FF7ED1', '#6B004F', '#FFA52F', '#573B00', '#005659', '#0000DD', '#00FDCF', '#A17569', '#BCB6FF', '#95B577']

  useEffect(() => {
    if (!data) return;
    const formatted = [];
    const labels = data.stats['benthos-pie-chart'].labels;
    const values = data.stats['benthos-pie-chart'].values;
    // Sum data to get percent values
    const sum = values.reduce((p, c) => p + c, 0)
    for (let i = 0; i < labels.length; i++) {
      formatted.push({
        name: labels[i],
        value: Math.round((values[i]/sum) * 100) / 100,
      });
    };
    setCoralPieData(formatted);
  }, [data]);
      
      
    return (
      <>
        <PieChart width={250} height={150} title='Substrate Topics'>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={coralPieData}
            outerRadius={60}
            label
          >
            {coralPieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </>
    );
};

export default CoralChart;

import React, { useEffect, useRef, useCallback, useState } from 'react';



const SketchFabView = ({ uid, selected_texture, reef, date }) => {

    const meshRef = useRef(null);
    const [textures, setTextures] = useState({
        corrected: {
            name: 'Corrected',
            url: `${window.location.origin}/data/${reef}/${date}/textures/corrected_basecolor.jpg`,
            uid: null
        },
        substrate: {
            name: 'Substrate',
            url: `${window.location.origin}/data/${reef}/${date}/textures/substrate_basecolor.jpg`,
            uid: null
        },
        anomalies: {
            name: 'Anomalies',
            url: `${window.location.origin}/data/${reef}/${date}/textures/anomalies_basecolor.jpg`,
            uid: null
        },
        coral: {
            name: 'Coral',
            url: `${window.location.origin}/data/${reef}/${date}/textures/coral_basecolor.jpg`,
            uid: null
        },
        rugosity: {
            name: 'Rugosity',
            url: `${window.location.origin}/data/${reef}/${date}/textures/rugosity_basecolor.jpg`,
            uid: null
        }
    });
    const [_material, setMaterial] = useState(null);
    const [_api, setApi] = useState(null);

    const success = useCallback((api) => {
        console.log(`Viewer API version ${api.version}`)
        api.start();
        console.log(`Started viewer`);
        api.addEventListener('viewerready', () => {
            window.console.log('viewer ready');
        
            api.getMaterialList((err, materials) => {
                const material = materials[0];
                console.log(`Got material, ${material.name}`);
                for (const texture in textures) {
                    console.log(`Registering new texture, ${textures[texture].name}`);
                    api.addTexture(textures[texture].url, (err, uid) => {
                        if (!err) {
                            textures[texture].uid = uid;
                            console.log(`Registered new texture, ${textures[texture].name}, uid: ${uid}`);
                        } else {
                            console.log(`Error registering new texture, ${textures[texture].name}`);
                            console.log(err);
                        }
                    });
                }
                console.log(`Setup textures`);
                setApi(api);
                setMaterial(material);
                setTextures(textures);
            });
        });
    }, [reef, date]);

    const error = useCallback((err) => {
        window.console.log('Viewer error');
        window.console.log(err);
    }, []);

    // Update texture when selected_texture changes
    useEffect(() => {
        console.log(`Attempting to update texture to ${selected_texture}`);
        if (!_api || !_material || !textures[selected_texture].uid) {
            console.log(`Not updating texture to ${selected_texture}`);
            return;
        }
        console.log(`States ready; updating texture to ${selected_texture}`);
        let materialToUpdate = _material;
        materialToUpdate.channels.AlbedoPBR.texture.uid = textures[selected_texture].uid;
        materialToUpdate.channels.AlbedoPBR.enable = true;
        _api.setMaterial(materialToUpdate, () => {
          window.console.log('Updated material.');
        });
        console.log(`Updated texture to ${selected_texture}`);
    }, [selected_texture, textures, _api, _material]);

    // Update Sketchfab model when UID changes
    useEffect(() => {
        const client = new window.Sketchfab('1.12.1', meshRef.current);

        client.init(uid, {
            annotation: 0, // Usage: Setting to [1 – 100] will automatically load that annotation when the viewer starts.
            annotations_visible: 1, // Usage: Setting to 0 will hide annotations by default.
            autospin: 0, // Usage: Setting to any other number will cause the model to automatically spin around the z-axis after loading.
            autostart: 1, // Usage: Setting to 1 will make the model load immediately once the page is ready, rather than waiting for a user to click the Play button.
            cardboard: 0, // Usage: Start the viewer in stereoscopic VR Mode built for Google Cardboard and similar devices.
            camera: 1, // Usage: Setting to 0 will skip the initial animation that occurs when a model is loaded, and immediately show the model in its default position.
            preload: 1, // Usage: Setting to 1 will force all resources (textures) to download before the scene is displayed.
            ui_stop: 0, // Usage: Setting to 0 will hide the "Disable Viewer" button in the top right so that users cannot stop the 3D render once it is started.
            transparent: 0, // Usage: Setting to 1 will make the model's background transparent
            ui_animations: 0, // Usage: Setting to 0 will hide the animation menu and timeline.
            ui_annotations: 0, // Usage: Setting to 0 will hide the Annotation menu.
            ui_controls: 1, // Usage: Setting to 0 will hide all the viewer controls at the bottom of the viewer (Help, Settings, Inspector, VR, Fullscreen, Annotations, and Animations).
            ui_fullscreen: 1, // Usage: Setting to 0 will hide the Fullscreen button.
            ui_general_controls: 1, // Usage: Setting to 0 will hide main control buttons in the bottom right of the viewer (Help, Settings, Inspector, VR, Fullscreen).
            ui_help: 1, // Usage: Setting to 0 will hide the Help button.
            ui_hint: 1, // Usage: Setting to 0 will always hide the viewer hint animation ("click & hold to rotate"). Setting to 1 will show the hint the first time per browser session (using a cookie). Setting to 2 will always show the hint.
            ui_infos: 0, // Usage: Setting to 0 will hide the model info bar at the top of the viewer.
            ui_inspector: 0, // Usage: Setting to 0 will hide the inspector button.
            ui_settings: 1, // Usage: Setting to 0 will hide the Settings button.
            ui_vr: 0, // Usage: Setting to 0 will hide the View in VR button.
            ui_ar: 0, // Usage: Setting to 0 will hide the View in AR button.
            ui_watermark_link: 0, // Usage: Setting to 0 remove the link from the Sketchfab logo watermark.
            ui_color: '00a8c0', // Usage: Setting to a hexidecimal color code (without the #) or a HTML color name will change the color of the viewer loading bar.
            ui_watermark: 0, // Usage: Setting to 0 remove the Sketchfab logo watermark.
          
            success: success,
            error: error
          });

    }, [uid]);

    return (
        <iframe title="sketchfab viewer" ref={meshRef} style={{ "width":"100%", "height":"100%" }} />
    );
};

export default SketchFabView;

import React, { PureComponent, useEffect, useState } from 'react';
import { PieChart, Pie, Legend, Tooltip, Cell } from "recharts";

const CoralChart = ({ data }) => {

  const [coralPieData, setCoralPieData] = useState([]);


  const COLORS = {
    "Reefs and invertebrates": '#EF767A',
    "Aquatic plants and seagrass": '#DFF8EB',
    "Wrecks and ruins": '#74776B',
    "Seafloor and rocks": '#5A7D7C'
  };

  useEffect(() => {
    if (!data) return;
    const formatted = [];
    const labels = data.stats['benthos-pie-chart'].labels;
    const values = data.stats['benthos-pie-chart'].values;
    // Sum data to get percent values
    const sum = values.reduce((p, c) => p + c, 0)
    for (let i = 0; i < labels.length; i++) {
      formatted.push({
        name: labels[i],
        value: Math.round((values[i]/sum) * 100) / 100,
      });
    };
    setCoralPieData(formatted);
  }, [data]);
      
      
    return (
      <>
        <PieChart width={250} height={180} title='Coral Cover'>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={coralPieData}
            outerRadius={60}
            label
          >
            {coralPieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[entry.name]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </>
    );
};

export default CoralChart;

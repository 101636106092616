import React, { PureComponent, useCallback, useEffect, useState } from 'react';
import { PieChart, Pie, Legend, Tooltip } from "recharts";
import { Stack } from '@chakra-ui/react';
import CoralChart from './CoralChart';
import SubstrateChart from './SubstrateChart';

const PlotStats = ({ reef, date }) => {
  const [substrateStats, setSubstrateStats] = useState(null);
  const [coralStats, setCoralStats] = useState(null);
  const [rugosityStats, setRugosityStats] = useState(null);

  useEffect(() => {
    fetch(`/data/${reef}/${date}/substrate.json`).then((response) => {
      return response.json();
    }).then((data) => {
      setSubstrateStats(data);
    });
    fetch(`/data/${reef}/${date}/coral.json`).then((response) => {
      return response.json();
    }).then((data) => {
      setCoralStats(data);
    });
    fetch(`/data/${reef}/${date}/rugosity.json`).then((response) => {
      return response.json();
    }).then((data) => {
      setRugosityStats(data);
    });
  }, []);
      
      
    return (
        <Stack align="center" style={{"padding":"10px"}}>
          <CoralChart data={coralStats} />
          <h5>Coral Cover</h5>
          <SubstrateChart data={substrateStats} />
          <h5>Substrate Classes</h5>
        </Stack>
    );
};

export default PlotStats;

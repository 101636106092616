import SketchFabView from './SketchFabView.js';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react'
import { 
  Flex,
  Box,
  Select,
  Card,
  CardBody,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
 } from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'
import { Image } from '@chakra-ui/react'
import theme from "./theme/theme.js";
import { useState } from 'react';
import PlotStats from './PlotStats.js';


function App() {
  const [reef, setReef] = useState('usvi_joels_shoal');
  const [texture, setTexture] = useState('corrected');
  const [date, setDate] = useState('2023-10-26');
  const [drawerOpen, setDrawerOpen] = useState(false);

  const models = {
    'usvi_joels_shoal': {
      '2023-10-26': '5e38dc7171534ec38fe9bd00788e5002',
      '2022-11-03': 'aa5645febdad4be39267a3f771dcd22a'
    }
  }

  const ControlForm = () => {
    console.log('Rerenderin')
    return (
      <Stack>
        <Box>
          <Select value={reef} onChange={e => setReef(e.target.value)}>
            <option value='usvi_joels_shoal'>USVI Joel's Shoal</option>
          </Select>
        </Box>
        <Box>
          <Select value={date} onChange={e => setDate(e.target.value)}>
            <option value='2023-10-26'>2023-10-26</option>
            <option value='2022-11-03'>2022-11-03</option>
          </Select>
        </Box>
        <Box>
          <RadioGroup value={texture} onChange={value => setTexture(value)}>
            <Stack>
              <Radio value='corrected'>Color</Radio>
              <Radio value='substrate'>Substrate</Radio>
              <Radio value='anomalies'>Anomalies</Radio>
              <Radio value='coral'>Coral</Radio>
              <Radio value='rugosity'>Rugosity</Radio>
            </Stack>
          </RadioGroup>
        </Box>
      </Stack>
    )
  }
    


  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        <Flex 
          className="mobile-header"
          display={{base: "flex", md: "none"}}
          backgroundColor="gray.800"
          justifyContent="left"
          alignItems="center"
          gap="4"
        >
          <button onClick={() => setDrawerOpen(true)}>
              <HamburgerIcon />
          </button>
          <Image src='img/tektite.png' alt='Tektite' className='tektite-logo-sm' />
          <Heading size="md">TEKTITE AI</Heading>
        </Flex>
        <Box className="mobile-controls" display={{sm: "block", md: "none"}}>
          <Drawer
            isOpen={drawerOpen}
            placement='left'
            onClose={() => setDrawerOpen(false)}
          >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>TEKTITE AI</DrawerHeader>
            <DrawerBody>
              <ControlForm />
              <PlotStats reef={reef} date={date} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        </Box>
        <Box className='floating-boxes' display={{base: "none", md: "block"}}>
          <Flex gap="5" alignItems="stretch" justifyContent="center" alignContent="center" flexDirection="column">
            <Card className="controls">
              <CardBody>
                <Stack>
                  <Flex direction="column" alignItems="center">
                        <Image src='img/tektite.png' alt='Tektite' className='tektite-logo' />
                        <Heading size="lg">TEKTITE AI</Heading>
                  </Flex>
                  <ControlForm />
                </Stack>
              </CardBody>
            </Card>
            <Card style={{"paddingTop": "10px"}}>
              <PlotStats reef={reef} date={date} />
            </Card>
          </Flex>
        </Box>
        <div className="canvas">
            <SketchFabView uid={models[reef][date]} selected_texture={texture} reef={reef} date={date} />
        </div>
      </div>
    </ChakraProvider>
  );
}

export default App;
